<template>
    <app-wrapper>
        <template v-slot:child>
            <!-- Top content-->
            <InventoryHeader :type="'PRODUCT'" :page="'ProductSummary'" :title="'Products & Services'" />
            <div v-if="plan?.businessPlan === 'Micro Business Plan'">
                <access-denied />
            </div>
            <div v-else-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(5)">
                <!-- total stock, total value of stock on hand, average inventory day -->
                <div class="w-90 center mv4 adj-text">
                    <div class="flex flex-wrap">
                        <div class="w-25-l w-50 pa3 box-border">
                            <div class="flex items-center pb3">
                                <img src="../../assets/images/revenue.png" alt="" />
                                <div class="pl2">
                                    Total stock on hand
                                    <span>
                                        <font-awesome-icon
                                            icon="fa-solid fa-question-circle"
                                            class="dashboard-icon-modal"
                                            @mouseover="totalStockOnHand = true"
                                            @mouseleave="totalStockOnHand = false"
                                        />
                                    </span>
                                    <div class="outstanding-modal" v-if="totalStockOnHand">
                                        This shows the current quantity of items available in your inventory. It helps you keep
                                        track of your stock levels and ensures you never run out of essential products.
                                    </div>
                                </div>
                            </div>
                            <div class="f5 f4-l font-w2">{{ formatQuantity(summary?.stockAtHand) }}</div>
                        </div>
                        <div class="w-25-l w-50 pa3 box-border">
                            <div class="flex items-center pb3">
                                <img src="../../assets/images/revenue.png" alt="" />
                                <div class="pl2">
                                    Total Stock On Hand Purchase Value
                                    <span>
                                        <font-awesome-icon
                                            icon="fa-solid fa-question-circle"
                                            class="dashboard-icon-modal"
                                            @mouseover="handPurchaseValue = true"
                                            @mouseleave="handPurchaseValue = false"
                                        />
                                    </span>
                                    <div class="outstanding-modal" v-if="handPurchaseValue">
                                        This represents the total cost of all the items currently in stock. It helps you
                                        understand the investment tied up in your inventory. It is calculated as (Qty in stock *
                                        Average purchase price/buying price)
                                    </div>
                                </div>
                            </div>
                            <div class="f5 f4-l font-w2">
                                {{ formatAmount(summary?.stockAtHandPurchaseValue, $store.state.Settings?.currency) }}
                            </div>
                        </div>

                        <div class="w-25-l w-50 pa3 box-border">
                            <div class="flex items-center pb3">
                                <img src="../../assets/images/revenue.png" alt="" />
                                <div class="pl2">
                                    Stock On Hand Sales Value
                                    <span>
                                        <font-awesome-icon
                                            icon="fa-solid fa-question-circle"
                                            class="dashboard-icon-modal"
                                            @mouseover="handSalesValue = true"
                                            @mouseleave="handSalesValue = false"
                                        />
                                    </span>
                                    <div class="outstanding-modal" v-if="handSalesValue">
                                        This provides an estimate of your inventory value were you to sell all off at the current
                                        average sales price. It is calculated as (Qty in stock * Average sales price/Selling
                                        price).
                                    </div>
                                </div>
                            </div>
                            <div class="f5 f4-l font-w2">
                                {{ formatAmount(summary?.stockAtHandSalesValue, $store.state.Settings?.currency) }}
                            </div>
                        </div>

                        <div class="w-25-l w-50 pa3 box-border">
                            <div class="flex items-center pb3">
                                <img src="../../assets/images/revenue.png" alt="" />
                                <!-- <div class="pl2">Average inventory daily</div> -->
                                <div class="pl2">
                                    Number of days inventory
                                    <span>
                                        <font-awesome-icon
                                            icon="fa-solid fa-question-circle"
                                            class="dashboard-icon-modal"
                                            @mouseover="noOfDaysInventory = true"
                                            @mouseleave="noOfDaysInventory = false"
                                        />
                                    </span>
                                    <div class="outstanding-modal" v-if="noOfDaysInventory">
                                        This is how long it takes a company, on average, to sell all
                                        the products they have in their store. It aids in inventory planning and prevents
                                        understocking/overstocking
                                    </div>
                                </div>
                            </div>
                            <div class="f5 f4-l font-w2">{{ formatQuantity(summary?.noOfDaysInInventory) }}</div>
                        </div>
                    </div>
                </div>
                <!-- total stock, total value of stock on hand, average inventory day -->

                <!-- total qty, total cost, total qty sale , total sale -->
                <div class="w-90 center pt4 adj-text">
                    <div class="flex flex-wrap">
                        <div class="w-25-l w-50 pa3 box-border">
                            <div class="flex items-center pb3">
                                <img src="../../assets/images/total-qty.svg" alt="" />
                                <div class="pl2">
                                    Total Qty purchase
                                    <span>
                                        <font-awesome-icon
                                            icon="fa-solid fa-question-circle"
                                            class="dashboard-icon-modal"
                                            @mouseover="totalQtyPurchase = true"
                                            @mouseleave="totalQtyPurchase = false"
                                        />
                                    </span>
                                    <div class="outstanding-modal" v-if="totalQtyPurchase">
                                        This shows the overall quantity of items purchased over a specific period. It gives you
                                        insights into your buying patterns and helps in understanding demand fluctuations.
                                    </div>
                                </div>
                            </div>
                            <div class="f5 f4-l b">{{ formatQuantity(summary?.totalQuantityPurchased) }}</div>
                        </div>
                        <div class="w-25-l w-50 pa3 box-border">
                            <div class="flex items-center pb3">
                                <img src="../../assets/images/total-cost.png" alt="" />
                                <div class="pl2">
                                    Total Cost Of Purchase
                                    <span>
                                        <font-awesome-icon
                                            icon="fa-solid fa-question-circle"
                                            class="dashboard-icon-modal"
                                            @mouseover="totalCostOfPurchase = true"
                                            @mouseleave="totalCostOfPurchase = false"
                                        />
                                    </span>
                                    <div class="outstanding-modal" v-if="totalCostOfPurchase">
                                        This represents the total cost incurred for purchasing all the items within a certain
                                        timeframe. It's vital for tracking expenses and calculating profit margins.
                                    </div>
                                </div>
                            </div>
                            <div class="f5 f4-l b">
                                {{ formatAmount(summary?.totalPurchaseAmount, $store.state.Settings?.currency) }}
                            </div>
                        </div>
                        <div class="w-25-l w-50 pa3 box-border">
                            <div class="flex items-center pb3">
                                <img src="../../assets/images/qty-sale.svg" alt="" />
                                <div class="pl2">
                                    Total Qty. sold
                                    <span>
                                        <font-awesome-icon
                                            icon="fa-solid fa-question-circle"
                                            class="dashboard-icon-modal"
                                            @mouseover="totalQtySold = true"
                                            @mouseleave="totalQtySold = false"
                                        />
                                    </span>
                                    <div class="outstanding-modal" v-if="totalQtySold">
                                        This indicates the total quantity of items sold over a specific period. It helps you
                                        assess your sales performance and measure customer demand.
                                    </div>
                                </div>
                            </div>
                            <div class="f5 f4-l b">{{ formatQuantity(summary?.totalQuantitySold) }}</div>
                        </div>
                        <div class="w-25-l w-50 pa3 box-border">
                            <div class="flex items-center pb3">
                                <img src="../../assets/images/total-sale.svg" alt="" />
                                <div class="pl2">
                                    Total Sales
                                    <span>
                                        <font-awesome-icon
                                            icon="fa-solid fa-question-circle"
                                            class="dashboard-icon-modal"
                                            @mouseover="totalSalesPrice = true"
                                            @mouseleave="totalSalesPrice = false"
                                        />
                                    </span>
                                    <div class="outstanding-modal" v-if="totalSalesPrice">
                                        This displays the total revenue generated from selling all the items within a specific
                                        timeframe. It's crucial for evaluating your business's financial performance.
                                    </div>
                                </div>
                            </div>
                            <div class="f5 f4-l b">
                                {{ formatAmount(summary?.totalSalesPrice, $store.state.Settings?.currency) }}
                            </div>
                        </div>
                    </div>
                </div>
                <!-- total qty, total cost, total qty sale , total sale -->

                <!-- top product in inventory & top selling product -->

                <!--          Start-->

                <div
                    class="flex flex-wrap justify-between w-90 center mv4"
                    :class="$store.state.Alert?.showMobileMenu && 'unZIndex'"
                >
                    <!-- top product -->
                    <!--                <div class="mv4 box-border top-product">-->
                    <div class=" box-border top-product h-100">
                        <div class="pa3 b box-border-bottom">TOP PRODUCTS IN STOCK</div>
                        <div class="ph3">
                            <div class="flex justify-between box-border-bottom" style="padding-top: 15px">
                                <div class="font-w2">Item</div>
                                <div class="font-w2">Qty</div>
                            </div>

                            <div
                                v-for="(topStock, index) of topProductsInStock"
                                :key="topStock?._id"
                                class=" flex items-center justify-between box-border-bottom"
                                style="padding-top: 1.1rem; padding-bottom: 1rem;"
                            >
                                <div class="flex items-center">
                                    <div class="pr2" style="border-right: 2px solid #e3e8ee">{{ index + 1 }}</div>
                                    <div class="flex items-center pl2">
                                        <img :src="topStock?.imageUrl || defaultImage" alt="" class="default-prod-image" />
                                        <a class="pl2 b hyperLink" style="color: #132c8c" :href="'/inventories/product/' + topStock._id">{{ topStock?.name }}</a>
                                    </div>
                                </div>
                                <div class="flex">
                                    <!-- style="padding-top: 1.2rem; padding-bottom: 1rem;" -->
                                    <!-- <img src="../../assets/images/increase.svg" alt="" /> -->
                                    <div class="pl2">{{ topStock?.qty_in_stock }}</div>
                                </div>
                            </div>
                            <div v-for="i in 5" :key="i">
                                <div class="flex items-center pv3" v-if="i > topProductsInStock?.length">
                                    <div class="pr2" style="border-right: 2px solid #e3e8ee">{{ i }}</div>
                                    <div class="flex items-center pl2">
                                        <img :src="topStock?.imageUrl || defaultImage" alt="" class="default-prod-image" />
                                        <div class="pl2">-</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- {{ topProductsInStock.length }} -->
                        <!--    <div class="tc pv3 box-border font-w1">View all transaction &#8594;</div>-->
                    </div>
                    <!-- top selling product desktop -->
                    <!-- <div class="mv4 box-border top-selling db-l dn h-100">
                        <div class="pa3 box-border-bottom b">Top selling product</div>
                        <div class="ph3">
                            <div class="pv3 flex justify-between box-border-bottom b">
                                <div class="w-40">ITEM</div>
                                <div>QTY SOLD</div>
                                <div>CHANGES</div>
                                <div>AVG PRICE</div>
                                <div>SALES</div>
                            </div>

                            <div v-for="topProd of topSellingProducts" :key="topProd?._id" class="pv2 box-border-bottom">
                                <div class="flex justify-between items-center">
                                    <div class="w-40 flex items-center">
                                        <img :src="defaultImage" alt="" class="default-prod-image" />
                                        <div class="pl2">{{ topProd?.name }}</div>
                                    </div>
                                    <div>{{ formatQuantity(topProd?.sales_qty) }}</div>
                                    <div class="flex">
                                        <img src="../../assets/images/increase.svg" alt="" />
                                        <div class="pl2">{{ formatQuantity(topProd?.qty_in_stock) }}</div>
                                    </div>
                                    <div>{{ formatAmount(topProd?.sales_avg) }}</div>
                                    <div>{{ formatAmount(topProd?.sales_cost) }}</div>
                                </div>
                            </div>
                        </div>
                    </div> -->

                    <div
                        class="scrollmenu center box-border table-overflow tbsummary"
                        :class="$store.state.Alert?.showMobileMenu && 'unZIndex'"
                    >
                        <div class="pa3 b box-border-bottom">TOP SELLING PRODUCTS</div>

                        <table class="w-100 tl mt3 p2" cellspacing="0">
                            <thead>
                                <tr>
                                    <th class="bb b--black-20 tl items-center">
                                        Item
                                    </th>
                                    <th class="bb b--black-20 tl pr3">
                                        <span class="flex items-center">
                                            Qty Sold
                                        </span>
                                    </th>
                                    <th class="bb b--black-20 tl pr3">
                                        <span class="flex items-center">
                                            Qty in Stock
                                        </span>
                                    </th>
                                    <th class="bb b--black-20 tl">
                                        <span class="flex items-center">
                                            Avg Price
                                        </span>
                                    </th>
                                    <th class="bb b--black-20 tl">
                                        <span class="flex items-center">
                                            Total Sales
                                        </span>
                                    </th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr v-for="topProd of topSellingProducts" :key="topProd?._id" class="font-w1">
                                    <td class="pv3 bb b--black-20 tl font-w2 items-center tooltip">
                                        <img :src="topProd?.imageUrl || defaultImage" alt="" class="default-prod-image" />
                                        <a :href="'/inventories/product/' + topProd._id" class="pl2 hyperLink" style="color: #132c8c" >{{
                                            topProd?.name?.length > 20 ? `${topProd?.name?.slice(0, 20)}...` : topProd?.name
                                        }}</a>
                                        <span
                                            v-if="topProd?.name?.length > 20"
                                            class="tooltiptext summarytt"
                                            style="padding: 10px;"
                                            
                                            >{{ topProd?.name }}</span
                                        >
                                    </td>
                                    <td class="pv3 pr4 bb b--black-20 tl items-center">
                                        {{ formatQuantity(topProd?.sales_qty) }}
                                    </td>
                                    <td class="pv3 pr4 bb b--black-20 tl ">
                                        <!-- <img src="../../assets/images/increase.svg" alt="" /> -->
                                        <div class="pl2">{{ formatQuantity(topProd?.qty_in_stock) }}</div>
                                    </td>
                                    <td class="pv3 pr4 bb b--black-20 tl ttc tooltip">
                                        {{ formatAmount(topProd?.sales_avg, $store.state.Settings?.currency) }}
                                    </td>
                                    <td class="pv3 pr4 bb b--black-20 tl ttc tooltip">
                                        {{ formatAmount(topProd?.sales_cost, $store.state.Settings?.currency) }}
                                    </td>
                                </tr>
                                <tr v-for="i of 5" :key="i" class="font-w1">
                                    <template v-if="i > topProductsInStock?.length">
                                        <td
                                            class="pv3 bb b--black-20 tl font-w2 items-center tooltip"
                                            style="padding-right: 4rem"
                                        >
                                            <img :src="topProd?.imageUrl || defaultImage" alt="" class="default-prod-image" />
                                            <span class="pl2">-</span>
                                        </td>
                                        <td class="pv3 pr4 bb b--black-20 tl items-center">
                                            -
                                        </td>

                                        <td class="pv3 pr4 bb b--black-20 tl flex">
                                            <div class="pl2">-</div>
                                        </td>
                                        <td class="pv3 pr4 bb b--black-20 tl ttc tooltip">
                                            -
                                        </td>
                                        <td class="pv3 pr4 bb b--black-20 tl ttc tooltip">
                                            -
                                        </td>
                                    </template>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <!-- top selling product mobile -->
                    <!-- <div class="mv4 box-border top-product h-100 dn-l db">
                        <div class="pa3 b box-border-bottom">Top selling product</div>
                        <div class="ph3">
                            <div class="pv3 flex justify-between box-border-bottom">
                                <div class="font-w2">PRODUCT</div>
                                <div class="font-w2">CHANGES</div>
                            </div>

                            <div
                                v-for="(topProd, index) of topSellingProducts"
                                :key="topProd?._id"
                                class="pv2 flex items-center justify-between box-border-bottom"
                            >
                                <div class="flex items-center">
                                    <div class="pr2" style="border-right: 2px solid #e3e8ee">{{ index + 1 }}</div>
                                    <div class="flex items-center pl2">
                                        <img :src="topProd?.imageUrl || defaultImage" alt="" class="default-prod-image" />
                                        <div class="pl2">{{ topProd?.name }}</div>
                                    </div>
                                </div>
                                <div class="flex">
                                    <img src="../../assets/images/increase.svg" alt="" />
                                    <div class="pl2">5.7%</div>
                                </div>
                            </div>
                        </div>
                    </div> -->
                    <!-- <div class="box-border w-100 dn-l db">
                        <div class="b box-border-bottom pa3">Top Selling Product</div>
                        <div class="pa3  table-overflow">
                            <div v-for="topProd of topSellingProducts" :key="topProd?._id" class="box-border-bottom">
                                <div class="flex items-center pv3">
                                    <img :src="topProd?.imageUrl || defaultImage" alt="" class="default-prod-image" />
                                    <div class="pl3 b">{{ topProd?.name }}</div>
                                </div>
                                <div class="flex justify-between pv3">
                                    <div class="w-10"></div>
                                    <div class="w-34" style="color: #84818a">CHANGES</div>
                                    <div class="w-50 flex pl3">
                                        <img src="../../assets/images/increase.svg" alt="" />
                                        <div class="pl1">5.7%</div>
                                    </div>
                                </div>
                                <div class="flex justify-between pv3">
                                    <div class="w-10"></div>
                                    <div class="w-34" style="color: #84818a">AVG PRICE</div>
                                    <div class="w-50 pl3">{{ formatAmount(topProd?.sales_avg) }}</div>
                                </div>
                                <div class="flex justify-between pv3">
                                    <div class="w-10"></div>
                                    <div class="w-34" style="color: #84818a">QTY SOLD</div>
                                    <div class="w-50 pl3">{{ formatQuantity(topProd?.sales_qty) }}</div>
                                </div>
                                <div class="flex justify-between pv3">
                                    <div class="w-10"></div>
                                    <div class="w-34" style="color: #84818a">SALES</div>
                                    <div class="w-50 pl3">{{ formatAmount(topProd?.sales_cost) }}</div>
                                </div>
                            </div>
                        </div>
                    </div> -->
                </div>

                <!--End-->
                <!-- top product in inventory & top selling product -->

                <!-- revenue distribution, Recent sale, low stock -->

                <!--          Start-->
                <!--            <div class="w-90 center flex flex-wrap justify-between mv4">-->
                <div class="w-90 center flex flex-wrap mv4" style="gap: 1rem">
                    <!--            <div class="w-90 center flex flex-wrap mv4" style="gap: 1rem">-->
                    <!-- Recent Sales -->
                    <div class="box-border recent-sale h-100">
                        <div class="pa3 box-border-bottom">
                            <p class="b">RECENT SALES</p>
                        </div>
                        <div class="pa3">
                            <div
                                v-for="sale of recentSales"
                                :key="sale?._id"
                                class="flex justify-between pv2"
                                style="border-bottom: 1px solid #e3e8ee"
                            >
                                <div class="flex items-start pb2">
                                    <img src="../../assets/images/file.svg" alt="" />
                                    <div class="pl2">
                                        <p class="b pb1" v-if="sale.entityName && !sale.entityName.includes('undefined')">{{ sale?.entityName }}</p>
                                        <p class="b pb1" v-else>Clientes Varios</p>
                                        <p>{{ new Date(sale?.date || sale?.code).toLocaleString() }}</p>
                                    </div>
                                </div>
                                <div class="tr">
                                    <p class="b pb1">{{ formatAmount(sale?.amount, $store.state.Settings?.currency) }}</p>
                                    <p class="green" v-if="!sale.credit">Completed</p>
                                    <p class="red" v-else>Unpaid</p>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="pa3 tc box-border font-w1"><p>View all transactions &#8594;</p></div>-->
                    </div>

                    <!-- low stock -->
                    <div class="low-stock box-border h-100" :class="$store.state.Alert?.showMobileMenu && 'unZIndex'">
                        <div>
                            <div class="pa3 box-border-bottom b">LOW STOCK</div>
                            <div class="ph3 pb4">
                                <div class="pv2 b flex justify-between box-border-bottom">
                                    <div>Item</div>
                                    <div>Qty</div>
                                </div>

                                <div
                                    v-for="(stock, index) of lowStocks"
                                    :key="stock?._id"
                                    class="pv2 flex items-center justify-between box-border-bottom"
                                >
                                    <div class="flex items-center">
                                        <div class="pr2" style="border-right: 2px solid #e3e8ee">
                                            {{ index + 1 }}
                                        </div>
                                        <div class="flex items-center pl2 tooltip">
                                            <img :src="stock?.imageUrl || defaultImage" alt="" class="default-prod-image" />
                                            <div class="pl2">
                                                {{ stock?.name?.length > 14 ? `${stock?.name?.slice(0, 14)}...` : stock?.name }}
                                                <span
                                                    v-if="stock?.name?.length > 14"
                                                    class="tooltiptext summaryttnext"
                                                    style="padding: 10px;"
                                                    >{{ stock?.name }}</span
                                                >
                                            </div>
                                        </div>
                                    </div>
                                    <div>{{ formatQuantity(stock?.qty_in_stock) }}</div>
                                </div>
                            </div>
                            <!--                        <div class="tc pv3 box-border font-w1">View all transaction &#8594;</div>-->
                        </div>
                    </div>

                    <!-- revenue distribution -->
                    <!-- <div class="revenue-dist box-border h-100"> -->
                    <!-- <div class="pa3 w-100"> -->
                    <!-- <div class="b">Revenue distribution</div>
                            <div class="pv3 tc">
                                <Doughnut
                                    :chart-options="chartOptions"
                                    :chart-data="revenueGraphData"
                                    :chart-id="lineId"
                                    :plugins="plugins"
                                    :css-classes="cssClasses"
                                    :styles="styles"
                                    :width="width"
                                    :height="height"
                                />
                            </div> -->
                    <!-- <div>
                                        <div class="flex justify-between">
                                            <div>
                                                <p class="pb1">Branch A</p>
                                                <div class="flex">
                                                    <img src="../../assets/images/blue-dot.svg" alt="" />
                                                    <p class="pl2 b" style="color: #132c8c">23,000</p>
                                                    <p class="pl2">-3%</p>
                                                </div>
                                            </div>
                                            <div>
                                                <p class="pb1">Branch B</p>
                                                <div class="flex">
                                                    <img src="../../assets/images/green-dot.svg" alt="" />
                                                    <p class="pl2 b" style="color: #132c8c">6,000</p>
                                                    <p class="pl2">-3%</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="flex justify-between pt4">
                                            <div>
                                                <p class="pb1">Branch C</p>
                                                <div class="flex">
                                                    <img src="../../assets/images/yellow-dot.svg" alt="" />
                                                    <p class="pl2 b" style="color: #132c8c">13,000</p>
                                                    <p class="pl2">-3%</p>
                                                </div>
                                            </div>
                                            <div>
                                                <p class="pb1">Branch D</p>
                                                <div class="flex">
                                                    <img src="../../assets/images/Shape.svg" alt="" />
                                                        <p class="pl2 b" style="color: #132c8c">3,000</p>
                                                        <p class="pl2">-3%</p>
                                                    </div>
                                                </div>
                                            </div>
                                            </div> -->
                    <!-- <div v-for="data of inventoryGraphData" v-bind:key="data.id">
                                        <div class="flex">
                                        <div>
                                                <p class="pb1">{{data.name}}</p>
                                                <div class="flex">
                                                    <p class="pl2 b" :style="data.color">{{data.amount}}</p>
                                                </div>
                                            </div>
                                            
                                        </div>
                                        </div> -->
                    <!-- <div v-else-if="index > 1 && index < 4" class="flex justify-between pt4">
                                            <div>
                                                <p class="pb1">{{data.name}}</p>
                                                <div class="flex">
                                                    <img src="../../assets/images/yellow-dot.svg" alt="" />
                                                    <p class="pl2 b" style="color: #132c8c">{{data.amount}}</p>
                                                </div>
                                            </div>
                                            </div> -->
                    <!-- <div class="flex justify-center"><h3>Coming soon...</h3></div> -->
                    <!-- </div> -->
                    <!-- </div> -->
                </div>
            </div>

            <!--          End-->
            <!-- revenue distribution, Recent sale, low stock -->
        </template>
    </app-wrapper>
</template>

<script>
import { computed, onMounted, watch, reactive, ref } from 'vue'
import { useStore } from 'vuex'
import AppWrapper from '@/layout/AppWrapper'
import InventoryHeader from '@/views/Inventory/widgets/InventoryHeader'
import { formatAmount, formatQuantity } from '@/utils/lib'
import { Doughnut } from 'vue-chartjs'
import Swal from 'sweetalert2'
import AccessDenied from '@/components/AccessDenied'

import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    LinearScale,
    LineElement,
    PointElement,
    Plugin,
    Filler,
    ArcElement
} from 'chart.js'

ChartJS.register(Filler, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, LineElement, PointElement, ArcElement)

const defaultImage = require('@/assets/images/inventory-default.png')

export default {
    name: 'AllProductSummary',
    components: { AppWrapper, InventoryHeader, Doughnut, AccessDenied },

    setup() {
        const store = useStore()
        const summary = computed(() => store.state.Inventory.summary)
        const topProductsInStock = computed(() => store.state.Inventory.topProductsInStock)
        const topSellingProducts = computed(() => store.state.Inventory.topSellingProducts)
        const lowStocks = computed(() => store.state.Inventory.lowStock)
        const recentSales = computed(() => store.state.Inventory.recentSales)
        const inventoryGraphData = computed(() => store.state.Inventory.inventoryGraphData)
        const rolePermissions = computed(() => store?.state?.Auth?.rolePermissions)
        const role = computed(() => store?.state?.Auth?.role)
        const plan = computed(() => store.state?.Settings?.paymentPlan)

        const state = reactive({
            inventoryGraphData: []
        })
        onMounted(() => {
            store.dispatch('Settings/getPlan')
            store.dispatch('Inventory/getInventorySummary')
            store.dispatch('Inventory/getTopInventoryInStock')
            store.dispatch('Inventory/getTopSellingProducts')
            store.dispatch('Inventory/getRecentSales')
            store.dispatch('Inventory/getLowStocks')
            store.dispatch('Inventory/getInventoryGraphData')
        })

        watch(
            () => inventoryGraphData.value,
            (prevValue, newValue) => {
                const amountValues = prevValue.map(value => value.amount)
                const colorValues = prevValue.map(value => value.color)
                const nameValues = prevValue.map(value => value.name)
                // const { data } = prevValue
                // console.log(amountValues, data);
                state.inventoryGraphData = {
                    labels: nameValues,
                    datasets: [
                        {
                            label: 'Revenue Distribution',
                            data: amountValues,
                            backgroundColor: colorValues
                        }
                    ]
                }
            }
        )

        // Modal for inventory

        const totalStockOnHand = ref(false)
        const handPurchaseValue = ref(false)
        const handSalesValue = ref(false)
        const noOfDaysInventory = ref(false)
        const totalQtyPurchase = ref(false)
        const totalCostOfPurchase = ref(false)
        const totalQtySold = ref(false)
        const totalSalesPrice = ref(false)

        const revenueGraphData = computed(() => state.inventoryGraphData)

        const chartOptions = computed(() => {
            return {
                responsive: true,
                plugins: {
                    legend: {
                        position: 'bottom'
                    }
                    //   title: {
                    //     display: true,
                    //     text: 'Chart.js Doughnut Chart'
                    //   }
                }
            }
        })

        return {
            formatAmount,
            formatQuantity,
            summary,
            topProductsInStock,
            topSellingProducts,
            lowStocks,
            recentSales,
            defaultImage,
            chartOptions,
            revenueGraphData,
            inventoryGraphData,
            role,
            rolePermissions,
            totalStockOnHand,
            handPurchaseValue,
            handSalesValue,
            noOfDaysInventory,
            totalQtyPurchase,
            totalCostOfPurchase,
            totalQtySold,
            totalSalesPrice,
            plan
        }
    }
}
</script>

<style scoped>
.default-prod-image {
    width: 2.32rem;
    height: 1rem;
    object-position: center;
    object-fit: cover;
}

.tbsummary {
    width: 67%;
}

.summarytt {
    margin-left: 0px !important;
}

.summaryttnext {
    margin-left: -60px !important;
}
.hyperLink:hover{
    text-decoration: underline;
}

@media screen and (max-width: 700px) {
    .topProductSummaryMobile > div {
        overflow-y: hidden;
        height: 220px;
        width: 300px;
        display: inline-block;
        white-space: nowrap;
        -webkit-overflow-scrolling: touch;
    }

    .tbsummary {
        width: 100%;
    }
}

@media screen and (max-width: 1200px) {
    .tbsummary {
        width: 100%;
    }
}
</style>
